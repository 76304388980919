export default {
  apiUrl: 'http://yoursite.com/api/',
}

const siteConfig = {
  siteName: 'XONE-CASH',
  siteIcon: 'ion-flash',
  footerText: 'XONE-CASH ©2019 Created by Trade',
}
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
}
const language = 'english'

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
}

export { siteConfig, language, themeConfig, jwtConfig }
